import React from "react"
import { Button } from "react-bootstrap"

const LoadingButton = ({ loading, children, loadingText, ...rest }) => {
  return (
    <Button {...rest} disabled={loading || rest.disabled}>
      {loading ? (
        rest.size === "sm" ? (
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="spinner-border spinner-border-xsm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            <div>{loadingText || "Loading..."}</div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            <div>{loadingText || "Loading..."}</div>
          </div>
        )
      ) : (
        children
      )}
    </Button>
  )
}

export default LoadingButton
