import React from "react"

import LogoIcon from "./LogoIcon"

const CardLoader = ({ height = "150px" }) => (
  <div
    style={{ height }}
    className="d-flex align-items-center justify-content-center"
  >
    <LogoIcon isSpining={true} />
    {/* <span
      className="spinner-border text-secondary full-page-spinner"
      role="status"
      aria-hidden="true"
    ></span> */}
  </div>
)

export default CardLoader
