import React, { useEffect, useState } from "react"

import FullPageLoader from "components/common/FullPageLoader"
import Rating from "react-rating"
import { Card, Col, Container, Form, Row } from "react-bootstrap"
import LoadingButton from "components/common/LoadingButton"
import CardLoader from "components/common/CardLoader"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"

import "react-toastify/dist/ReactToastify.min.css"
import NotFoundPage from "./404"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import partners from "images/review-feature.png"
import starActive from "images/star-active.png"
import starInactive from "images/star-inactive.png"
import reviewIllustration from "images/Reviews-01.svg"

const isBrowser = typeof window !== "undefined"

function Page() {
  const { t } = useTranslation("review")
  const { i18n } = useI18next()

  useEffect(() => {
    console.log(i18n.resolvedLanguage)
    if (i18n.resolvedLanguage === "ar") {
      document.body.style.direction = "rtl"
      document.body.style.fontFamily = "Noto Kufi Arabic"
    } else {
      document.body.style.direction = "ltr"
      document.body.style.fontFamily = "Poppins"
    }
  }, [i18n.resolvedLanguage])

  const [shipment, setShipment] = useState(null)
  const [loading, setLoading] = useState(true)
  const [submiting, setSubmiting] = useState(false)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState("")
  const [currentOrderDetailIndex, setCurrentOrderDetailIndex] = useState(1)
  const [reviewCompleted, setReviewCompleted] = useState(false)
  const queryString =
    typeof window !== "undefined" ? window.location.search : undefined
  const urlParams = new URLSearchParams(queryString)
  const shipment_review_uuid = urlParams.get("id")
  const APP_HOST = process.env.GATSBY_APP_HOST

  const REVIEW_URL = new URL(
    `api/shipment-review/${shipment_review_uuid}`,
    APP_HOST
  ).toString()
  const fetchData = async () => {
    if (isBrowser) {
      try {
        setLoading(true)
        const res = await fetch(REVIEW_URL)
        const content = await res.json()
        setShipment(content)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const setCurrentOrderDetailIndexHandler = is_forward => {
    if (is_forward) {
      if (
        shipment.details.filter(d => !d.review).length == 0 ||
        shipment.details.filter(d => !d.review).length ==
          currentOrderDetailIndex
      ) {
        setReviewCompleted(true)
        return
      }
      setCurrentOrderDetailIndex(currentOrderDetailIndex + 1)
      transitioningToNextOrderDetailHandler(currentOrderDetailIndex + 1)
    } else {
      setCurrentOrderDetailIndex(currentOrderDetailIndex - 1)
      transitioningToNextOrderDetailHandler(currentOrderDetailIndex - 1)
    }
  }

  const transitioningToNextOrderDetailHandler = index => {
    const anchorNode = document.getElementById(`order-detail-${index}-anchor`)
    console.log({ index, anchorNode })
    if (anchorNode) {
      anchorNode.click()
    }
  }

  const currentOrderDetail = !shipment
    ? null
    : shipment.details.filter(d => !d.review)[currentOrderDetailIndex - 1]

  const submitReviewHandler = async () => {
    try {
      setSubmiting(true)
      const response = await fetch(REVIEW_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_detail_uuid: currentOrderDetail.uuid,
          rating,
          comment,
        }),
      })

      setComment("")
      setRating(0)
      setCurrentOrderDetailIndexHandler(true)
    } catch (error) {
      console.log(error)
    } finally {
      setSubmiting(false)
    }
  }

  if (loading) {
    return (
      <>
        <Seo
          title={`${t("Shipper")} | ${t("How was your experience?")}`}
          description={t(
            "Your feedback is valuable to us and helps us improve the quality of our products and services. Simply leave a review, rating, and any comments about your shipment."
          )}
          keywords={t("shipment reviews, shipment review, order review")}
          image={partners}
        />
        <FullPageLoader />
      </>
    )
  }

  if (!shipment) {
    return (
      <>
        <Seo
          title={`${t("Shipper")} | ${t("How was your experience?")}`}
          description={t(
            "Your feedback is valuable to us and helps us improve the quality of our products and services. Simply leave a review, rating, and any comments about your shipment."
          )}
          keywords={t("shipment reviews, shipment review, order review")}
          image={partners}
        />
        <NotFoundPage />
      </>
    )
  }

  return (
    <>
      <Seo
        title={`${t("Shipper")} | ${t("How was your experience?")}`}
        description={t(
          "Your feedback is valuable to us and helps us improve the quality of our products and services. Simply leave a review, rating, and any comments about your shipment."
        )}
        keywords={t("shipment reviews, shipment review, order review")}
        image={partners}
      />
      <Container>
        <div className="g-0 min-vh-100 flex-center row py-2">
          <Card>
            <Card.Header className="text-center bg-card-gradient py-3">
              <h5 className="text-white mb-0">{t("Product review")}</h5>
            </Card.Header>
            <Card.Body>
              {reviewCompleted ||
              shipment?.details?.filter(d => !d.review)?.length < 1 ? (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img width="350px" src={reviewIllustration} />
                  <h5 className="mt-2 d-flex align-items-center">
                    {t("Thank you for your review")}{" "}
                    <span className="mx-1" style={{ fontSize: "15px" }}>
                      ❤️
                    </span>
                  </h5>
                </div>
              ) : (
                <Row>
                  <Col md="6">
                    <div className="slider">
                      {shipment.details
                        .filter(d => !d.review)
                        .map((product, index) => (
                          <a
                            key={index}
                            id={`order-detail-${index + 1}-anchor`}
                            className="d-none"
                            href={`#order-detail-${index + 1}-of-${
                              shipment.details.filter(d => !d.review).length
                            }`}
                          >
                            a
                          </a>
                        ))}
                      <div className="slides overflow-y-hidden mb-2">
                        {shipment.details
                          .filter(d => !d.review)
                          .map(({ product }, index) => (
                            <div
                              key={index}
                              id={`order-detail-${index + 1}-of-${
                                shipment.details.filter(d => !d.review).length
                              }`}
                            >
                              <div className="d-flex flex-column px-2">
                                <img
                                  alt={product.name}
                                  src={
                                    product.is_variation
                                      ? product?.variation_media?.original_url
                                      : product?.media?.[0]?.original_url
                                  }
                                  style={{
                                    width: "100%",
                                    maxHeight: "250px",
                                    objectFit: "contain",
                                  }}
                                />
                                <h6
                                  className="text-center mt-2 mb-0 text-truncate text-500"
                                  style={{ direction: "ltr" }}
                                >
                                  {product.name}
                                </h6>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <hr className="d-md-none" />
                  </Col>
                  <Col md="6">
                    {submiting ? (
                      <div className="py-4 h-100">
                        <CardLoader height="100%" />
                      </div>
                    ) : (
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                        }}
                        className="h-100"
                      >
                        <div className="d-flex justify-content-between flex-column h-100">
                          <div>
                            <Form.Group className="mt-1 mb-2">
                              <h6 style={{ lineHeight: "1.7rem" }}>
                                {t(
                                  "Your satisfaction is our top priority. Help us make sure we're meeting your needs by sharing your opinion on the received products."
                                )}
                              </h6>
                              <div className="d-flex align-items-center justify-content-center">
                                <Rating
                                  direction={
                                    i18n.resolvedLanguage === "ar"
                                      ? "rtl"
                                      : "ltr"
                                  }
                                  key={`stars_${rating}`}
                                  className="ms-2"
                                  initialRating={rating}
                                  onChange={rating => {
                                    setRating(rating)
                                  }}
                                  fullSymbol={
                                    <img
                                      src={starActive}
                                      width="30px"
                                      className="mx-1 mb-1 p-0 "
                                    />
                                  }
                                  emptySymbol={
                                    <img
                                      src={starInactive}
                                      width="30px"
                                      className="mx-1 mb-1 p-0 "
                                    />
                                  }
                                />
                              </div>
                            </Form.Group>
                            <Form.Control
                              as="textarea"
                              placeholder={t("Leave a comment here")}
                              rows={7}
                              value={comment}
                              onChange={e => setComment(e.target.value)}
                            />
                          </div>
                          <div className="d-grid g-2">
                            <LoadingButton
                              // block
                              className="mt-2 border-0"
                              variant="primary"
                              disabled={rating == 0}
                              onClick={() => {
                                submitReviewHandler()
                              }}
                              style={{
                                backgroundImage:
                                  "linear-gradient(42deg, #307b61, #269b4bb3)",
                              }}
                              loading={submiting}
                            >
                              {t("Submit review")}
                            </LoadingButton>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["review"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Page
